import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'

class InformativaClienti extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Privacy Policy Clienti" description={'Consulta la privacy policy per tutti i clienti Dinamiza.'} />

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}><Light>Privacy policy clienti</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>

                  <h3>INFORMATIVA AI SENSI AI SENSI DELL’ ART. 13 DEL REGOLAMENTO UE 2016/679</h3>
                  <p>Ai sensi dell’art. 13 e 14 del Regolamento (UE) n. 2016/679 (di seguito anche “GDPR” o “Regolamento”), Starsoftware Srl (di seguito anche “Starsoftware” o il “Titolare”) Vi informa che i dati personali (di seguito i “Dati”), relativi a Voi o alla Vostra impresa (di seguito il “Cliente”) e alle persone fisiche che agiscono per suo conto, raccolti presso il Cliente o presso terzi, saranno trattati nel rispetto di quanto previsto dal Regolamento (UE) n. 2016/679 e in conformità all’informativa che segue. Resta inteso che è responsabilità del Cliente informare le persone fisiche che agiscono per suo conto del trattamento dei Dati di cui alla presente informativa e richiedere loro, ove necessario, il consenso.</p>

                  <h4>TITOLARE DEL TRATTAMENTO</h4>
                  <p>Il Titolare del trattamento è Starsoftware Srl, con sede legale in Via Verga n. 3/A, 30038, Spinea (VE), Italia,<br />P.Iva 03352380277
                    <ul className="lista_generica lista_competenze">
                     <li>email <a href="mailto:amministrazione@starsoftware.it" target="_blank"></a>amministrazione@starsoftware.it.</li>
                     <li>PEC <a href="mailto:starsoftware@pec.it" target="_blank"></a>amministrazione@starsoftware.it.</li>
                    </ul>
                  </p>

                  <h4>FINALITÀ DEL TRATTAMENTO E BASE GIURIDICA</h4>
                  <p>I dati servono al Titolare per dar seguito alla richiesta di iscrizione anagrafica ed al contratto di fornitura di prodotti/ servizi acquistati, gestire ed eseguire le richieste di contatto inoltrate dal Cliente, fornire assistenza, adempiere agli obblighi di legge e regolamentari cui il Titolare è tenuto in funzione dell’attività esercitata. In nessun caso Starsoftware rivende i dati personali del Cliente a terzi né li utilizza per finalità non dichiarate.<br />In particolare i dati del Cliente saranno trattati per:
                    <ul className="lista_generica lista_competenze">
                     <li><strong>l’iscrizione anagrafica e le richieste di contatto e/o di materiale informativo</strong><br />Il trattamento dei dati personali del Cliente avviene per dar corso alle attività preliminari e conseguenti alla richiesta di iscrizione anagrafica, alla gestione delle richieste di informazioni e di contatto e/o di invio di materiale informativo. Base giuridica di tali trattamenti è l’adempimento delle prestazioni inerenti alla richiesta di iscrizione, di informazioni e di contatto e/o di invio di materiale informativo art.6 c.1 lett. b) del Regolamento.</li>
                     <li><strong>Finalità connesse alla gestione del rapporto contrattuale</strong><br />I dati saranno trattati per: instaurazione, gestione ed estinzione del rapporto contrattuale e commerciale conseguenti all’acquisto di prodotti/ servizi, la gestione del relativo ordine, la produzione e/o la spedizione del Prodotto acquistato e/o l’erogazione del Servizio; adempimento degli obblighi contabili e fiscali; adempimento degli obblighi di legge; verifiche di natura fiscale e contabile; gestione dei contenziosi; erogazione, supporto, aggiornamento ed informazione in merito ai Prodotti offerti.<br />Base giuridica di tali trattamenti è l’adempimento delle prestazioni inerenti il rapporto contrattuale ed il rispetto di obblighi di legge art.6 c.1 lett. b) e lett. f) del Regolamento.</li>
                     <li><strong>Finalità connesse ad attività promozionali</strong>I dati saranno trattati per iniziative di invio materiale informativo / promozionale e informazioni sulle attività e sugli eventi a cui partecipa Starsoftware o organizzati da Starsoftware stessa. Tale trattamento può avvenire, in modo automatizzato, con le seguenti modalità:<br />E-mail<br/>Contatto telefonico.Base giuridica del trattamento è ai sensi dell’art. 6 c.1 lett. f) “liceità del trattamento basata sul legittimo interesse del Titolare del Trattamento”.</li>
                     <li><strong>Adempimenti ad obblighi di legge</strong>I dati saranno trattati per adempiere agli obblighi legali, ai quali il Titolare è soggetto (ad es. obblighi contabili, retributivi, previdenziali, controlli antiterrorismo).<br />Base giuridica di tali trattamenti è l’adempimento delle prestazioni inerenti il rapporto contrattuale ed il rispetto di obblighi di legge art. 6 c.1 lett. c) del GDPR.</li>
                    </ul>
                  </p>

                  <h4>CONFERIMENTO DEI DATI E CONSEGUENZE IN CASO DI MANCATO CONFERIMENTO</h4>
                  <p>Il conferimento dei Dati per le finalità sub a), b) e d) è facoltativo, ma necessario ai fini dell’adempimento di obblighi legali e contrattuali e il perseguimento dei legittimi interessi del Titolare indicati sopra. In tutti questi casi, il mancato conferimento dei Dati comporterà l’impossibilità per il Titolare di instaurare rapporti commerciali con il Cliente. Il conferimento dei Dati per la finalità sub c) è facoltativo e il suo mancato conferimento comporterà l’impossibilità per il Titolare di svolgere le attività funzionali a raggiungere la finalità in questione.</p>

                  <h4>MODALITA’ DI TRATTAMENTO DEI DATI</h4>
                  <p>È interesse primario di Starsoftware tutelare i dei propri Clienti, improntando il loro trattamento ai principi di correttezza, di liceità e trasparenza. Pertanto i dati personali saranno trattati, tramite l’utilizzo di strumenti e procedure idonei a garantirne la massima sicurezza e riservatezza, mediante archivi e supporti cartacei, con l’ausilio di supporti digitali, mezzi informatici e telematici.</p>

                  <h4>PERIODO DI CONSERVAZIONE</h4>
                  <p>I Dati saranno conservati per un periodo di tempo massimo pari al periodo di prescrizione dei diritti azionabili dal Titolare, come di volta in volta applicabile.</p>
                  <p>A prescindere dalla determinazione del Cliente alla loro rimozione, i dati personali saranno in ogni caso conservati secondo i termini previsti dalla vigente normativa e/o dai regolamenti nazionali, al fine esclusivo di garantire gli adempimenti.</p>
                  <p>Altresì, i dati personali saranno in ogni caso conservati per l’adempimento degli obblighi (es. fiscali e contabili) che permangono anche dopo la cessazione del contratto (art. 2220 c.c.); per tali fini il Titolare conserverà solo i dati necessari al relativo perseguimento.</p>
                  <p>Sono fatti salvi i casi in cui si dovessero far valere in giudizio i diritti derivanti dal contratto e/o dall’iscrizione anagrafica, nel qual caso i dati personali del Cliente, esclusivamente quelli necessari per tali finalità, saranno trattati per il tempo indispensabile al loro perseguimento.</p>

                  <h4>DESTINATARI o CATEGORIE di DESTINATARI</h4>
                  <p>La comunicazione dei dati personali del Cliente avviene principalmente nei confronti di terzi e/o destinatari la cui attività è necessaria per l’espletamento delle attività inerenti al rapporto instaurato e per rispondere a determinati obblighi di legge, quali:</p>
                  <table style={{width: '100%'}} cellpadding="20">
                    <tbody>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '30px', width: '40%'}}><strong>Categorie di destinatari</strong></td>
                        <td style={{height: '30px', width: '60%'}}><strong>Finalit&agrave;</strong></td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Societ&agrave; del gruppo&nbsp;</td>
                        <td style={{height: '23px', width: '60%'}}>Adempimenti amministrativi, contabili e connessi alla prestazione contrattuale.</td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Societ&agrave; del Gruppo e fornitori terzi*</td>
                        <td style={{height: '23px', width: '60%'}}>Assistenza, manutenzione, consegna/spedizione prodotti, erogazione di servizi aggiuntivi connessi alla prestazione richiesta.</td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Istituti di credito e di pagamento digitale, Istituti bancari/postali</td>
                        <td style={{height: '23px', width: '60%'}}>Gestione di incassi, pagamenti, rimborsi connessi alla prestazione contrattuale.</td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Professionisti/Consulenti esterni e Societ&agrave; di consulenza</td>
                        <td style={{height: '23px', width: '60%'}}>Adempimento degli obblighi di legge, esercizio dei diritti, tutela dei diritti contrattuali, recupero del credito.</td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>Amministrazione finanziaria, Enti pubblici, Autorit&agrave; di vigilanza e controllo</td>
                        <td style={{height: '23px', width: '60%'}}>Adempimento degli obblighi di legge, difesa dei diritti; elenchi e registri tenuti da pubbliche Autorit&agrave; o enti similari in base a specigica normativa, in relazione alla prestazione contrattuale.</td>
                      </tr>
                      <tr style={{height: '23px'}}>
                        <td style={{height: '23px', width: '40%'}}>&nbsp;Soggetti formalmente delegati o aventi titolo giuridico riconosciuto</td>
                        <td style={{height: '23px', width: '60%'}}>&nbsp;Rappresentanti legali, curatori, tutori, etc.</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>*Il Titolare impone ai Terzi propri fornitori e ai Responsabili del trattamento il rispetto di misure di sicurezza ai sensi dell’art.32 del GDPR.</p>
                  <p>Il Titolare non trasferisce i Suoi dati personali in paesi nei quali non è applicato il GDPR (paesi extra UE) salvo specifiche indicazioni contrarie per le quali verrai preventivamente informato e se necessario verrà richiesto il Suo consenso.</p>

                  <h4>DIRITTI DEL CLIENTE</h4>
                  <p>Ai Clienti sono riconosciuti i diritti, nei casi e nei limiti previsti dal GDPR di cui agli art. da 15 a 20. A titolo esemplificativo, ciascun Cliente potrà:
                    <ul className="lista_generica lista_competenze">
                      <li>avere la conferma che sia o meno in corso un trattamento di dati personali che lo riguardano</li>
                      <li>qualora un trattamento sia in corso, ottenere l’accesso ai dati personali e alle informazioni relative al trattamento nonché richiedere una copia dei dati personali</li>
                      <li>ottenere la rettifica dei dati personali inesatti e l’integrazione dei dati personali incompleti</li>
                      <li>ottenere, qualora sussista una delle condizioni previste dall’art. 17 del GDPR, la cancellazione dei dati personali che lo riguardano</li>
                      <li>ottenere, nei casi previsti dall’art. 18 del GDPR, la limitazione del trattamento</li>
                      <li>ricevere i dati personali che lo riguardano in un formato strutturato, di uso comune e leggibile da dispositivo automatico e richiedere la loro trasmissione ad un altro titolare, se tecnicamente fattibile</li>
                    </ul>
                  </p>

                  <h4>OPPOSIZIONE AL TRATTAMENTO</h4>
                  <p>Il Cliente potrà revocare, in qualsiasi momento, il consenso al trattamento dei propri dati personali, inviando la richiesta a <a href="mailto:amministrazione@starsoftware.it">amministrazione@starsoftware.it</a> senza che ciò possa, tuttavia:
                    <ul className="lista_generica lista_competenze">
                      <li>pregiudicare la liceità del trattamento basato sul consenso prestato prima della revoca</li>
                      <li>pregiudicare ulteriori trattamenti degli stessi dati fondati su altre basi giuridiche (ad esempio, obblighi contrattuali o obblighi di legge cui è soggetta Starsoftware)</li>
                    </ul>
                  </p>

                  <h4>DIRITTO DI PROPORRE RECLAMO AL GARANTE</h4>
                  <p>Inoltre ciascun Cliente potrà proporre reclamo al Garante per la Protezione dei Dati Personali nel caso in cui ritenga che siano stati violati i diritti di cui è titolare ai sensi del GDPR, secondo le modalità indicate sul sito internet del Garante accessibile all’indirizzo: <a href="www.garanteprivacy.it" target="_blank">www.garanteprivacy.it</a></p>

                  <h4><a href="https://www.dinamiza.it/Informativa-clienti.pdf" target="_blank" >Scarica e stampa questa Informativa sulla Privacy</a></h4>

                </div>
                <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default InformativaClienti
